import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Col, Row, Button, Select, Checkbox, notification } from "antd";

import api from "../../../service/api";
import FormGrupoInput from "../../../components/FormGrupoInput";
import TituloTela from "../../../components/TituloTela";
import Maps from "../../../components/Maps";
import { Context } from "../../../Context/AuthContext";

import useInterval from "../../../hooks/useInterval";

import {
  ContainerFiltro,
  ContainerLabels,
  ContainerMapa,
  InfosContainer,
  MarkerVisibilityButton,
  SectionTitle,
} from "./styles";
import {
  addKeyToVehicle,
  inOperationColumns,
  countStatusReducer,
  mapToVehiclePosition,
  notInOperationColumns,
  STATUS_COLORS,
} from "./helpers";
import { DataTable } from "./components/table";
import { createColumnHelper } from "@tanstack/react-table";
import { ButtonContainer } from "./components/table/DataTableColumnHeader/styles";
import { DetailButtonContainer } from "./components/table/styles";
import { FaRegEye, FaRegEyeSlash, FaRegMap } from "react-icons/fa";

const { Option } = Select;

const columnHelper = createColumnHelper();

function ConsultaPosicaoVeiculo() {
  const { empresa } = useContext(Context);
  const [gestor, setGestor] = useState([]);
  const [isGestor, setIsGestor] = useState();
  const [usuario, setUsuario] = useState({});
  const [valueFiltro, setValueFiltro] = useState(null);
  const [dadosVeiculosEmOperacao, setDadosVeiculosEmOperacao] = useState([]);
  const [dadosVeiculosSemOperacao, setDadosVeiculosSemOperacao] = useState([]);
  const [checkBoxRefresh, setCheckBoxRefresh] = useState(false);
  const [dadosVeiculosEmOperacaoFilter, setDadosVeiculosEmOperacaoFilter] =
    useState([]);
  const [dadosVeiculosSemOperacaoFilter, setDadosVeiculosSemOperacaoFilter] =
    useState([]);
  const [locations, setLocations] = useState([]);
  const [vehiclesCoordinates, setVehiclesCoordinates] = useState([]);
  const [markersConfig, setMarkersConfig] = useState({
    info: {
      0: {
        name: "Atrasado",
        visible: true,
        count: 0,
      },
      1: {
        name: "Dentro do Prazo",
        visible: true,
        count: 0,
      },
      2: {
        name: "Programado e não iniciado",
        visible: true,
        count: 0,
      },
    },
    parking: {
      0: {
        name: "Andando sem operação",
        visible: true,
        count: 0,
      },
      1: {
        name: "Ocioso",
        visible: true,
        count: 0,
      },
    },
  });

  const flattenedMarkerVisibilityStatus = useMemo(() => {
    return Object.entries(markersConfig).flatMap(([type, statusObj]) =>
      Object.entries(statusObj).map(([status, data]) => ({
        type,
        status: Number(status),
        name: data.name,
        visible: data.visible,
        count: data.count,
      })),
    );
  }, [markersConfig]);

  const updateMarkersCount = useCallback((prev, type, counters) => {
    return {
      ...prev,
      [type]: Object.keys(prev[type]).reduce((acc, status) => {
        acc[status] = Object.assign({}, prev[type][status], {
          count: counters[status] || 0,
        });
        return acc;
      }, {}),
    };
  }, []);

  const [selectedTruckPosition, setSelectedTruckPosition] = useState({
    lat: -27.241389,
    lng: -48.633611,
  });

  const getGestores = useCallback(async () => {
    const { data } = await api.get(
      `/empresas/${empresa.id}/logistica/gestor_frota`,
    );

    const managerWithKey = data.map((operacao) => ({
      ...operacao,
      key: operacao.codigo,
    }));

    setGestor(managerWithKey);
  }, [empresa.id]);

  const getVehicles = useCallback(async () => {
    try {
      setVehiclesCoordinates([]);

      const { data: inOperationData } = await api.get(
        `/empresas/${empresa.id}/logistica/veiculos_em_operacao/${valueFiltro}`,
      );

      const { data: withoutOperationData } = await api.get(
        `/empresas/${empresa.id}/logistica/veiculos_sem_operacao/${valueFiltro}`,
      );

      const inOperationStatusCount = inOperationData.reduce(
        (acc, item) => countStatusReducer(acc, item),
        { atrasado: 0, dentroDoPrazo: 0, programadoNaoIniciado: 0 },
      );

      const withoutOperationStatusCount = withoutOperationData.reduce(
        (acc, item) => countStatusReducer(acc, item, "andando_sem_operacao"),
        { andandoSemOperacao: 0, ociosos: 0 },
      );

      setMarkersConfig((prev) => {
        const withInfoUpdated = updateMarkersCount(prev, "info", {
          0: inOperationStatusCount.atrasado,
          1: inOperationStatusCount.dentroDoPrazo,
          2: inOperationStatusCount.programadoNaoIniciado,
        });

        return updateMarkersCount(withInfoUpdated, "parking", {
          0: withoutOperationStatusCount.andandoSemOperacao,
          1: withoutOperationStatusCount.ociosos,
        });
      });

      setDadosVeiculosEmOperacao(inOperationData.map(addKeyToVehicle));
      setDadosVeiculosSemOperacao(withoutOperationData.map(addKeyToVehicle));

      setVehiclesCoordinates([
        ...inOperationData.map((v) =>
          mapToVehiclePosition(v, "info", "Em Operação"),
        ),
        ...withoutOperationData.map((v) =>
          mapToVehiclePosition(v, "parking", "Sem Operação"),
        ),
      ]);
    } catch (error) {
      notification.error({
        message: "Erro ao buscar as informações dos veículos"
      })
      console.error("Erro ao buscar as informações dos veículos", error);
    }
  }, [updateMarkersCount, empresa.id, valueFiltro]);

  const toggleMarkerVisibility = useCallback(({ type, status }) => {
    setMarkersConfig((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [status]: {
          ...prev[type][status],
          visible: !prev[type][status].visible,
        },
      },
    }));
  }, []);

  const columnsWithButton = useCallback(
    (columnsArray) => [
      columnHelper.display({
        id: "openDrawer",
        widthColumn: 120,
        header: ({ header }) => (
          <ButtonContainer right={`${header.column.getAfter("right")}px`}>
            Ações
          </ButtonContainer>
        ),
        cell: ({ row }) => {
          return (
            <DetailButtonContainer
              disabled={
                !row.original.latitude_loc_atual ||
                !row.original.longitude_loc_atual
              }
              onClick={() => {
                if (
                  row.original.latitude_loc_atual &&
                  row.original.longitude_loc_atual
                ) {
                  const recordType = row.original.andando_sem_operacao
                    ? "parking"
                    : "info";
                  const recordStatus = row.original.status;
                  if (
                    [recordStatus] in markersConfig[recordType] &&
                    !markersConfig[recordType][recordStatus].visible
                  ) {
                    toggleMarkerVisibility({
                      type: row.original.andando_sem_operacao
                        ? "parking"
                        : "info",
                      status: row.original.status,
                    });
                  }

                  setSelectedTruckPosition({
                    lat: Number.parseFloat(row.original.latitude_loc_atual),
                    lng: Number.parseFloat(row.original.longitude_loc_atual),
                  });
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }
              }}
              type="button"
            >
              <FaRegMap size={16} />
              Ver no mapa
            </DetailButtonContainer>
          );
        },
      }),
      ...columnsArray,
    ],
    [markersConfig, toggleMarkerVisibility],
  );

  useEffect(() => {
    setDadosVeiculosEmOperacaoFilter([...dadosVeiculosEmOperacao]);
    setDadosVeiculosSemOperacaoFilter([...dadosVeiculosSemOperacao]);

    const visibleVehicles = [...vehiclesCoordinates].reduce(
      (acc, vehicleMarker) => {
        if (
          markersConfig[vehicleMarker.type][vehicleMarker.status] &&
          markersConfig[vehicleMarker.type][vehicleMarker.status].visible
        ) {
          acc.push(vehicleMarker);
        }
        return acc;
      },
      [],
    );

    setLocations(visibleVehicles);
  }, [
    dadosVeiculosEmOperacao,
    dadosVeiculosSemOperacao,
    markersConfig,
    vehiclesCoordinates,
  ]);

  useEffect(() => {
    async function usuarios() {
      try {
        const { data: user } = await api.get(
          `/empresas/${empresa.id}/logistica/usuarios`,
        );

        await getGestores()

        if (user.gestor_frota === "S") {
          setGestor([
            {
              codigo: user.codigo,
              nome: user.nome,
            },
          ]);

          setIsGestor(true);
          setValueFiltro(user.codigo);

          return
        }

        setIsGestor(!false);
        setValueFiltro("Todos");
      } catch (error) {
        notification.error({
          message: "Erro ao buscar as informações dos veículos",
          description: error?.response?.data || "Estamos com problemas para buscar as informações de veículos. Tente novamente mais tarde."
        })
      }
    }
    usuarios();
  }, [empresa.id, getGestores]);

  useEffect(() => {
    if (valueFiltro) {
      getVehicles();
    }
  }, [getVehicles, valueFiltro])

  useInterval(() => {
    if (checkBoxRefresh) {
      getVehicles();
    } else {
      clearInterval(useInterval);
    }
  }, 20000);

  return (
    <div>
      <TituloTela
        codigoTela="02.78.1369"
        nomeTela="Consulta posição dos veículos"
      />
      <ContainerMapa>
        <Maps
          locations={locations}
          clickedElementOnTable={selectedTruckPosition}
        />
      </ContainerMapa>
      <ContainerFiltro>
        <Row className="row">
          <div className="boxFilter">
            <Col flex="0 1 150px">
              <FormGrupoInput label="Gestor">
                <Select
                  disabled={isGestor}
                  value={valueFiltro}
                  style={{ width: 160 }}
                  filterOption={(input, option) =>
                    option.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    setValueFiltro(value);
                  }}
                >
                  <Option value="0">Todos</Option>
                  {gestor.map((gestores) => (
                    <Option key={gestores.codigo} value={gestores.codigo}>
                      {gestores.codigo} - {gestores.nome}
                    </Option>
                  ))}
                </Select>
              </FormGrupoInput>
            </Col>

            <Col flex="0 1 60px">
              <div style={{ padding: "30px 0px 16px 8px" }}>
                <Button type="primary" onClick={() => getVehicles()}>
                  Atualizar veículos
                </Button>
              </div>
            </Col>

            <Col flex="120px 120px 120px 120px" style={{ paddingTop: "35px" }}>
              <span>
                <Checkbox onClick={(e) => setCheckBoxRefresh(e.target.checked)}>
                  Atualizar tela automaticamente
                </Checkbox>
              </span>
            </Col>
          </div>
        </Row>
      </ContainerFiltro>
      <InfosContainer>
        <ContainerLabels>
          <div>
            Clique nos botões abaixo para exibir/ocultar alguns marcadores do
            mapa:
          </div>
          <div className="subtitles">
            {flattenedMarkerVisibilityStatus.map((marker) => {
              return (
                <MarkerVisibilityButton
                  $bgColor={STATUS_COLORS[marker.type][marker.status]}
                  type="button"
                  className="toggleTypeVisibility"
                  key={`${marker.type}-${marker.status}`}
                  onClick={() =>
                    toggleMarkerVisibility({
                      type: marker.type,
                      status: marker.status,
                    })
                  }
                  data-active={
                    markersConfig[marker.type][marker.status].visible
                  }
                >
                  {markersConfig[marker.type][marker.status].visible ? (
                    <FaRegEye />
                  ) : (
                    <FaRegEyeSlash />
                  )}
                  {marker.name}: {marker.count}
                </MarkerVisibilityButton>
              );
            })}
          </div>
        </ContainerLabels>
        <SectionTitle>Veículos em Operação</SectionTitle>
        <Row>
          <Col span={24}>
            <DataTable
              columns={columnsWithButton(inOperationColumns)}
              dataSource={dadosVeiculosEmOperacaoFilter}
            />
          </Col>
        </Row>
        <SectionTitle>Veículos sem Operação</SectionTitle>
        <Row>
          <Col span={24}>
            <DataTable
              columns={columnsWithButton(notInOperationColumns)}
              dataSource={dadosVeiculosSemOperacaoFilter}
            />
          </Col>
        </Row>
      </InfosContainer>
    </div>
  );
}

export default ConsultaPosicaoVeiculo;
