import React from "react";
import { AiFillAlert, AiFillCheckCircle } from "react-icons/ai";
import { formatDateBrazil } from "../../../utils/format-date-brazil";
import { BsCircleFill } from "react-icons/bs";
import { DataTableColumnHeader } from "../../../components/Table/DataTableColumnHeader"

export const countStatusReducer = (accumulator, item, field = 'status') => {
  const statusKey = Object.keys(accumulator)[item[field]];
  return {
    ...accumulator,
    [statusKey]: (accumulator[statusKey] || 0) + 1
  };
};

export const mapToVehiclePosition = (vehicle, type = 'info', label = 'Em Operação') => ({
  uid: vehicle.codigo || vehicle.Codigo,
  lat: vehicle.latitude_loc_atual || '',
  lng: vehicle.longitude_loc_atual || '',
  placa: vehicle.placa,
  status: type === 'info' ? vehicle.status : vehicle.andando_sem_operacao,
  veiculos: label,
  type
});

export const addKeyToVehicle = vehicle => ({
  ...vehicle,
  key: vehicle.codigo
});

export const STATUS_COLORS = {
  info: {
    0: '#FD151B',
    1: '#29BF12',
    2: '#FACC15',
  },
  parking: {
    0: '#f97316',
    1: '#2563EB',
  }
}

function converteSegundosParaHora(segundos) {
  if (segundos > 0) {
    let segundosResto = 0;

    const dias = Math.trunc(segundos / 86400);
    segundosResto = segundos % 86400;

    const horas = Math.trunc(segundosResto / 3600);
    segundosResto = segundosResto % 3600;

    const minutos = Math.trunc(segundosResto / 60);
    segundosResto = segundosResto % 60;

    return `${dias} dias, ${horas} horas ${minutos} minutos`;

  }
  return '';
}

const inOperationColumns = [
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    title: "Status",
    accessorKey: "status",
    widthColumn: 60,
    align: 'center',
    cell: ({ row }) => (
      <BsCircleFill style={{ color: STATUS_COLORS.info[row.original.status] }} />
    ),
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Placa Cavalo" />,
    title: "Placa Cavalo",
    accessorKey: "placa",
    widthColumn: 98,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Tipo Carroceria" />,
    title: "Tipo Carroceria",
    accessorKey: "tipo_carroceria",
    widthColumn: 180,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Gestor" />,
    title: "Gestor",
    accessorKey: "gestor",
    widthColumn: 100,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Nome do Motorista" />,
    title: "Nome do Motorista",
    accessorKey: "nome_motorista",
    widthColumn: 200,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="TP" />,
    title: "TP",
    accessorKey: "tipo_ordem",
    widthColumn: 40,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Município Origem" />,
    title: "Município Origem",
    accessorKey: "desc_mun_expedidor",
    widthColumn: 220,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="UF O." />,
    title: "UF O.",
    accessorKey: "uf_expedidor",
    widthColumn: 80,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Município Destino" />,
    title: "Município Destino",
    accessorKey: "desc_mun_recebedor",
    widthColumn: 220,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="UF D." />,
    title: "UF D.",
    accessorKey: "uf_recebedor",
    widthColumn: 80,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Dt Hr Últ. Loc." />,
    title: "Dt Hr Últ. Loc.",
    accessorKey: "data_hora_evento",
    cell: ({ row }) => (
      <div>
        <span>{formatDateBrazil(row.original.data_hora_evento)}</span>
      </div>
    ),
    widthColumn: 200,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Rota" />,
    title: "Rota",
    accessorKey: "sit_rota",
    cell: ({ row }) => (
      (row.original.sit_rota === 0 && <AiFillAlert style={{ color: '#e74a3b' }} />) ||
      (row.original.sit_rota === 2 && <AiFillCheckCircle style={{ color: '#1cc88a' }} />)
    ),
    widthColumn: 50,
    align: 'center'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Dt Prog. Entr." />,
    title: 'Dt Prog. Entr.',
    accessorKey: 'data_programada_entrega',
    align: 'left',
    width: 150,
    cell: ({ row }) => (
      <div>
        <span>{formatDateBrazil(row.original.data_prev_entrega_parcial)}</span>
      </div>
    ),
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Dt Prev. Entr. Parc." />,
    title: 'Dt Prev. Entr. Parc.',
    accessorKey: 'data_prev_entrega_parcial',
    align: 'left',
    width: 200,
    cell: ({ row }) => (
      <div>
        <span>{formatDateBrazil(row.original.data_prev_entrega_parcial)}</span>
      </div>
    ),
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Justificativa de Atraso" />,
    title: 'Justificativa de Atraso',
    accessorKey: 'id_descricao_justificativa',
    align: 'left',
    cell: ({ row }) => {
      return <div>{row.original.id_descricao_justificativa}</div>
    }
  },
];

const notInOperationColumns = [
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    title: "Status",
    accessorKey: "status",
    widthColumn: 60,
    align: 'center',
    cell: ({ row }) => (
      <BsCircleFill style={{ color: STATUS_COLORS.parking[row.original.andando_sem_operacao] }} />
    ),
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Placa Cavalo" />,
    title: "Placa Cavalo",
    accessorKey: "placa",
    widthColumn: 98,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Tipo Carroceria" />,
    title: "Tipo Carroceria",
    accessorKey: "tipo_carroceria",
    widthColumn: 180,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Gestor" />,
    title: "Gestor",
    accessorKey: "gestor",
    widthColumn: 100,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Nome do Motorista" />,
    title: "Nome do Motorista",
    accessorKey: "nome_motorista",
    widthColumn: 200,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="TP" />,
    title: "TP",
    accessorKey: "tipo_ordem",
    widthColumn: 40,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Município Origem" />,
    title: "Município Origem",
    accessorKey: "desc_mun_expedidor",
    widthColumn: 220,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="UF O." />,
    title: "UF O.",
    accessorKey: "uf_expedidor",
    widthColumn: 80,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Município Destino" />,
    title: "Município Destino",
    accessorKey: "desc_mun_recebedor",
    widthColumn: 220,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="UF D." />,
    title: "UF D.",
    accessorKey: "uf_recebedor",
    widthColumn: 80,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Dt Hr Últ. Loc." />,
    title: "Dt Hr Últ. Loc.",
    accessorKey: "data_hora_evento",
    cell: ({ row }) => (
      <div>
        <span>{formatDateBrazil(row.original.data_hora_evento)}</span>
      </div>
    ),
    widthColumn: 200,
    align: 'left'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Tempo sem operação" />,
    title: 'Tempo sem operação',
    accessorKey: 'segundos_sem_op',
    align: 'left',
    width: 450,
    cell: ({ row }) => {
      return (
        <div>
          <span>{converteSegundosParaHora(row.original.segundos_sem_op)}</span>
        </div>
      )
    },
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Localização atual" />,
    title: 'Localização atual',
    accessorKey: 'localizacao_atual',
    align: 'left',
    width: 250,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Dt Hr Loc. Atual" />,
    title: 'Dt Hr Loc. Atual',
    accessorKey: 'dt_localizacao_atual',
    align: 'left',
    width: 400,
    render: ({ row }) => (
      <div>
        <span>{formatDateBrazil(row.original.dt_localizacao_atual)}</span>
      </div>
    ),
  },
];

export { inOperationColumns, notInOperationColumns }
