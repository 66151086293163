import React, { useEffect, useState, useContext } from "react";
import api from "../service/api";
import { round } from "../utils/round";
import { Row, Col, Table, Radio, Button, Alert } from "antd";
import { Line as LineChart } from "@ant-design/charts";
import Modal from "../components/Modal";
import FormModal from "../components/FormModal";
import styled from "styled-components";
import Loader from "../components/Loader";
import Card from "../components/Card";
import GrupoCards from "../components/GrupoCards";
import { Context } from "../Context/AuthContext";
import Text from "antd/lib/typography/Text";
import TitulosReceberComponent from "../components/TitulosReceber";
import TitulosReceberChequeComponent from "../components/TitulosReceberCheque";
import TitulosPagarComponent from "../components/TitulosPagar";
import TabelaResponsiva from "./TabelaResponsiva";
import { SearchOutlined } from "@ant-design/icons";
import { formatDateBrazil } from "../utils/format-date-brazil";
import { formatDecimal } from "../utils/format-decimal";

import TituloTela from "../components/TituloTela";

const titlesModal = {
  titulos_receber: {
    codigoTela: "01.74.1333",
    nomeTela: "Titulos a receber",
    tema: "white",
    fontSize: "medium",
    paddingBottom: "without",
  },

  titulos_receber_dia: {
    codigoTela: "01.74.1333",
    nomeTela: "Titulos a receber",
    tema: "white",
    fontSize: "medium",
    paddingBottom: "without",
  },

  titulos_receber_cheque: {
    codigoTela: "01.74.1334",
    nomeTela: "Titulos a receber cheque",
    tema: "white",
    fontSize: "medium",
    paddingBottom: "without",
  },

  titulos_receber_cheque_dia: {
    codigoTela: "01.74.1334",
    nomeTela: "Titulos a receber cheque",
    tema: "white",
    fontSize: "medium",
    paddingBottom: "without",
  },

  titulos_pagar: {
    codigoTela: "01.74.1335",
    nomeTela: "Titulos pagar",
    tema: "white",
    fontSize: "medium",
    paddingBottom: "without",
  },

  titulos_pagar_dia: {
    codigoTela: "01.74.1335",
    nomeTela: "Titulos pagar",
    tema: "white",
    fontSize: "medium",
    paddingBottom: "without",
  },
};

const ButtonStyled = styled.button`
  cursor: ${({ temSaldo }) => (temSaldo ? "pointer" : "no-drop")};
  width: 100%;
  height: auto;
  padding: 2px solid transparent;
  margin: 1px solid transparent;
  text-align: left;
  background: white;
  outline: none;
`;

const ButtonFluxoCaixa = styled.button`
  cursor: ${({ saldo }) => (saldo ? "pointer" : "no-drop")};
  width: 100%;
  height: auto;
  padding: 2px;
  margin: 1px solid transparent;
  text-align: right;
  background: white;
  outline: none;
`;

const InputSearch = styled.input`
  display: inline-block;
  width: 20%;
	margin: auto;
	padding: 4px;
	border: none;
  border: 1px solid #1e88e5;
  outline: none;

`;

const colunasTabelaContaDisponibilidade = [
  {
    title: "Código Acesso",
    dataIndex: "codigo_acesso",
    key: "codigo_acesso",
  },
  {
    title: "Conta",
    dataIndex: "descricao",
    key: "descricao",
  },
  {
    title: "Saldo",
    dataIndex: "saldo",
    key: "saldo",
    align: "right",
    render: (value) => (
      <Text
        style={{ color: Number.parseFloat(value) >= 0 ? "#1cc88a" : "#e74a3b" }}
        strong
      >
        {value}
      </Text>
    ),
  },
];

const DashboardFinanceiro = () => {
  const [titulosPagar, setTitulosPagar] = useState({});
  const [titulosReceber, setTitulosReceber] = useState({});
  const [contasDisponibilidade, setContasDisponibilidade] = useState([]);
  const [saldoTotalContas, setSaldoTotalContas] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tipoModalFinanceiro, setTipoModalFinanceiro] = useState(null);
  const [searchDiasFluxoCaixa, setDiasSearchFluxoCaixa] = useState("10");
  const [searchDiasInputFluxoCaixa, setDiasSearchInputFluxoCaixa] =
    useState("");
  const [fluxoCaixa, setFluxoCaixa] = useState([]);
  const [data, setData] = useState([]);
  const [erros, setErros] = useState();
  const { empresa } = useContext(Context);

  useEffect(() => {
    async function DashboardFinanceiro() {
      try {
        setLoading(true);

        Promise.all([
          await api.get(`intercarg/dashboard_financeiro/${empresa.id}`),
          await api.get(`saldo-disponibilidade/${empresa.id}`),
        ]).then((results) => {
          const resultTitulos = results[0];
          const resultContas = results[1];

          const { titulos_receber } = resultTitulos.data.response;
          const { titulos_pagar } = resultTitulos.data.response;
          const contasDisponibilidade = resultContas.data.response;

          let totalContas = 0;
          totalContas = contasDisponibilidade.reduce(
            (totalContas, conta) => totalContas + conta.saldo,
            0,
          );

          setSaldoTotalContas(round(totalContas, 2));

          const contasDisponibilidadeKey = contasDisponibilidade.map(
            (conta) => {
              return {
                codigo_acesso: conta.codigo_acesso,
                descricao: conta.descricao,
                saldo: formataValorMoeda(conta.saldo),
                key: conta.id,
              };
            },
          );

          setTitulosPagar(titulos_pagar);
          setTitulosReceber(titulos_receber);
          setContasDisponibilidade(contasDisponibilidadeKey);
          setLoading(false);
        });
      } catch (e) {
        if (e || e.response.data.statusCode >= 500) {
          const erro = "Erro 500, servidor não respondendo contate o suporte!";
          setErros(erro);
        } else {
          const erro = e.response.data.errors[0].message;
          setErros(erro);
        }
      } finally {
        setLoading(false);
      }
    }
    DashboardFinanceiro();
  }, [empresa]);

  function formataValorMoeda(value) {
    let valorFormatado = "--";

    if (value || value === 0) {
      valorFormatado = Number.parseFloat(value).toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    }
    return valorFormatado;
  }

  function handlerClickModalFinanceiro(tipoDocumento, prazo) {
    setTipoModalFinanceiro({
      tipoDocumento,
      prazo,
      titleModal: titlesModal[tipoDocumento],
    });
  }

  useEffect(() => {
    async function fetchFluxoCaixa() {
      try {
        setLoading(true);
        Promise.all([
          await api.get(`/empresas/${empresa.id}/financeiro/fluxo-caixa`, {
            params: { dias: searchDiasFluxoCaixa },
          }),
          await api.get(`saldo-disponibilidade/${empresa.id}`),
        ]).then((results) => {
          const resultFluxoCaixa = results[0];
          const resultContas = results[1];

          const fluxoCaixa = resultFluxoCaixa.data.response;
          const contasDisponibilidade = resultContas.data.response;

          let totalContas = 0;
          totalContas = contasDisponibilidade.reduce(
            (totalContas, conta) => totalContas + conta.saldo,
            0,
          );

          const contasDisponibilidadeKey = contasDisponibilidade.map(
            (conta) => {
              return {
                codigo_acesso: conta.codigo_acesso,
                descricao: conta.descricao,
                saldo: formataValorMoeda(conta.saldo),
                key: conta.id,
              };
            },
          );

          const fluxoCaixas = fluxoCaixa.map((item) => {
            return {
              data_vencimento: formatDateBrazil(
                item.data_vencimento,
                "dd/MM/yyyy",
              ),
              Dia_Semana: item.Dia_Semana,
              valor_tit_rec: formataValorMoeda(item.valor_tit_rec),
              valor_tit_rec_format: item.valor_tit_rec,
              valor_cheques: formataValorMoeda(item.valor_cheques),
              valor_cheques_format: item.valor_cheques,
              valor_tit_pag: formataValorMoeda(item.valor_tit_pag),
              valor_tit_pag_format: item.valor_tit_pag,
              saldo_dia: formataValorMoeda(item.saldo_dia),
              saldo_acumulado: formataValorMoeda(
                item.saldo_acumulado + totalContas,
              ),
            };
          });

          const graficoFluxoCaixa = [
            {
              valor: 0,
              dia: "0",
              category: "Conta a receber",
            },
            {
              valor: 0,
              dia: "0",
              category: "Contas pagar",
            },
            {
              valor: 0,
              dia: "0",
              category: "Saldo acumulado",
            },
          ];

          fluxoCaixa.forEach((ordem) => {
            const category = [
              {
                dia: formatDateBrazil(ordem.data_vencimento, "dd/MM"),
                dia_completo: formatDateBrazil(
                  ordem.data_vencimento,
                  "dd/MM/yyyy",
                ),
                valor: Number.parseFloat(
                  ordem.valor_tit_rec + ordem.valor_cheques,
                ),
                category: "Conta a receber",
              },
              {
                dia: formatDateBrazil(ordem.data_vencimento, "dd/MM"),
                dia_completo: formatDateBrazil(
                  ordem.data_vencimento,
                  "dd/MM/yyyy",
                ),
                valor: Number(ordem.valor_tit_pag * -1),
                category: "Contas pagar",
              },
              {
                dia: formatDateBrazil(ordem.data_vencimento, "dd/MM"),
                dia_completo: formatDateBrazil(
                  ordem.data_vencimento,
                  "dd/MM/yyyy",
                ),
                valor: Number(ordem.saldo_acumulado + totalContas),
                category: "Saldo acumulado",
              },
            ];
            graficoFluxoCaixa.push(category[0]);
            graficoFluxoCaixa.push(category[1]);
            graficoFluxoCaixa.push(category[2]);
          });

          setFluxoCaixa(fluxoCaixas);
          setData(graficoFluxoCaixa);
          setContasDisponibilidade(contasDisponibilidadeKey);
        });
        setLoading(false);
      } catch (e) {
        if (e || e.response.data.statusCode >= 500) {
          const erro = "Erro 500, servidor não respondendo contate o suporte!";
          setErros(erro);
        } else {
          const erro = e.response.data.errors[0].message;
          setErros(erro);
        }
      }
    }
    fetchFluxoCaixa();
  }, [empresa.id, searchDiasFluxoCaixa]);

  function handleClickDiasFluxoCaixa() {
    if (Number(searchDiasInputFluxoCaixa) && searchDiasInputFluxoCaixa > 0) {
      setDiasSearchFluxoCaixa(searchDiasInputFluxoCaixa);
    }
  }

  function handlerClickSearch(e) {
    setDiasSearchFluxoCaixa(e.target.value);
  }

  function changeInputFluxoCaixa(event) {
    setDiasSearchInputFluxoCaixa(event.target.value.replace(/[^0-9]/g, ""));
  }

  function closeModal() {
    setTipoModalFinanceiro(null);
  }

  function handlerClickDateFluxoCaixa(tipoDocumento, prazo, dataPeriodo) {
    setTipoModalFinanceiro({
      tipoDocumento,
      prazo,
      titleModal: titlesModal[tipoDocumento],
      dataPeriodo,
    });
  }

  const colunasFluxoCaixa = [
    {
      title: "Data",
      dataIndex: "data_vencimento",
      key: "data_vencimento",
    },
    {
      title: "Dia da semana",
      dataIndex: "Dia_Semana",
      key: "Dia_Semana",
    },
    {
      title: "Conta a Receber",
      dataIndex: "valor_tit_rec",
      key: "valor_tit_rec",
      align: "right",
      render: (text, record) => {
        return (
          <ButtonFluxoCaixa
            style={{ color: "#1cc88a" }}
            saldo={Number(record.valor_tit_rec_format) > 0}
            onClick={
              Number(record.valor_tit_rec_format) > 0
                ? () =>
                  handlerClickDateFluxoCaixa(
                    "titulos_receber_dia",
                    "dia_clicado",
                    record.data_vencimento,
                  )
                : () => { }
						}
          >
            {text}
          </ButtonFluxoCaixa>
        );
      },
    },
    {
      title: "Cheque pré Receber",
      dataIndex: "valor_cheques",
      key: "valor_cheques",
      align: "right",
      render: (text, record, index) => (
        <ButtonFluxoCaixa
          style={{ color: "#1cc88a" }}
          saldo={Number(record.valor_cheques_format) > 0}
          onClick={
            Number(record.valor_cheques_format) > 0
              ? () =>
                handlerClickDateFluxoCaixa(
                  "titulos_receber_cheque_dia",
                  "dia_clicado",
                  record.data_vencimento,
                )
              : () => { }
          }
        >
          {text}
        </ButtonFluxoCaixa>
      ),
    },
    {
      title: "Conta a pagar",
      dataIndex: "valor_tit_pag",
      key: "valor_tit_pag",
      align: "right",
      render: (text, record) => (
        <ButtonFluxoCaixa
          style={{ color: "#e74a3b" }}
          saldo={Number(record.valor_tit_pag_format) < 0}
          onClick={
            Number(record.valor_tit_pag_format) < 0
              ? () =>
                handlerClickDateFluxoCaixa(
                  "titulos_pagar_dia",
                  "dia_clicado",
                  record.data_vencimento,
                )
              : () => { }
          }
        >
          {text}
        </ButtonFluxoCaixa>
      ),
    },
    {
      title: "Saldo dia",
      dataIndex: "saldo_dia",
      key: "saldo_dia",
      align: "right",
      render: (value) => (
        <Text
          style={{
            color: Number.parseFloat(value) >= 0 ? "#1cc88a" : "#e74a3b",
          }}
          strong
        >
          {value}
        </Text>
      ),
    },
    {
      title: "Saldo acumulado",
      dataIndex: "saldo_acumulado",
      key: "saldo_acumulado",
      align: "right",
      render: (value) => (
        <Text
          style={{
            color: Number.parseFloat(value) >= 0 ? "#1cc88a" : "#e74a3b",
          }}
          strong
        >
          {value}
        </Text>
      ),
    },
  ];

  //Grafico

  const config = {
    data,
    padding: "auto",
    yField: "valor",
    xField: "dia",
    seriesField: "category",
    yAxis: {
      tickCount: 20,
    },
    xAxis: {
      tickCount: 20,
    },
    color: ["#1cc88a", "#cb302d", "#0061f7"],
    legend: {
      position: "top",
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
    tooltip: {
      fields: ["dia_completo", "valor", "category"],
      title: "dia_completo",
      formatter: (date) => ({
        name: date.category,
        value: formatDecimal(date.valor),
      }),
    },
  };

  return (
    <>
      {tipoModalFinanceiro && (
        <Modal openCloseModal={closeModal}>
          <FormModal title={tipoModalFinanceiro.titleModal} close={closeModal}>
            <div
              style={{
                padding: "0 10px 10px 10px",
                background: "#F3F4F6",
                height: "100%",
              }}
            >
              {tipoModalFinanceiro.tipoDocumento === "titulos_receber" && (
                <TitulosReceberComponent prazo={tipoModalFinanceiro.prazo} />
              )}
              {tipoModalFinanceiro.tipoDocumento ===
                "titulos_receber_cheque" && (
                  <TitulosReceberChequeComponent
                    prazo={tipoModalFinanceiro.prazo}
                  />
                )}
              {tipoModalFinanceiro.tipoDocumento === "titulos_pagar" && (
                <TitulosPagarComponent prazo={tipoModalFinanceiro.prazo} />
              )}
              {tipoModalFinanceiro.tipoDocumento === "titulos_receber_dia" && (
                <TitulosReceberComponent
                  prazo={tipoModalFinanceiro.prazo}
                  dataPeriodo={tipoModalFinanceiro.dataPeriodo}
                />
              )}
              {tipoModalFinanceiro.tipoDocumento === "titulos_pagar_dia" && (
                <TitulosPagarComponent
                  prazo={tipoModalFinanceiro.prazo}
                  dataPeriodo={tipoModalFinanceiro.dataPeriodo}
                />
              )}
              {tipoModalFinanceiro.tipoDocumento ===
                "titulos_receber_cheque_dia" && (
                  <TitulosReceberChequeComponent
                    prazo={tipoModalFinanceiro.prazo}
                    dataPeriodo={tipoModalFinanceiro.dataPeriodo}
                  />
                )}
            </div>
          </FormModal>
        </Modal>
      )}

      <Loader isLoading={loading} />

      <TituloTela codigoTela="01.74.1325" nomeTela="Dashboard Financeiro" />
      <Col md={24} lg={24} xs={24} sm={24} xl={24}>
        {erros && (
          <Alert
            closeText="Fechar"
            message={erros}
            showIcon
            type="error"
            action={
              <Button size="small" danger show={true}>
                Detalhes
              </Button>
            }
          />
        )}
      </Col>

      <GrupoCards titulo="Titulos a Receber">
        <Row gutter={[12, 16]}>
          <Col xs={24} sm={12} xl={6}>
            <ButtonStyled
              temSaldo={Number(titulosReceber.receber_vencidos) > 0}
              onClick={
                Number(titulosReceber.receber_vencidos) > 0
                  ? () =>
                    handlerClickModalFinanceiro("titulos_receber", "vencidos")
                  : () => { }
              }
            >
              <Card
                titulo="Vencidos"
                valor={formataValorMoeda(
                  Number(titulosReceber.receber_vencidos),
                )}
                cor="vermelho"
              />
            </ButtonStyled>
          </Col>

          <Col xs={24} sm={12} xl={6}>
            <ButtonStyled
              temSaldo={Number(titulosReceber.receber_vence_hoje) > 0}
              onClick={
                Number(titulosReceber.receber_vence_hoje) > 0
                  ? () => handlerClickModalFinanceiro("titulos_receber", "hoje")
                  : () => { }
              }
            >
              <Card
                titulo="Vence Hoje"
                valor={formataValorMoeda(
                  Number(titulosReceber.receber_vence_hoje),
                )}
                cor="azul"
              />
            </ButtonStyled>
          </Col>

          <Col xs={24} sm={12} xl={6}>
            <ButtonStyled
              temSaldo={Number(titulosReceber.receber_vence_sete_dias) > 0}
              onClick={
                Number(titulosReceber.receber_vence_sete_dias) > 0
                  ? () =>
                    handlerClickModalFinanceiro(
                      "titulos_receber",
                      "vence_sete_dias",
                    )
                  : () => { }
              }
            >
              <Card
                titulo="Vence em Sete Dias"
                valor={formataValorMoeda(
                  Number(titulosReceber.receber_vence_sete_dias),
                )}
                cor="verde"
              />
            </ButtonStyled>
          </Col>

          <Col xs={24} sm={12} xl={6}>
            <ButtonStyled
              temSaldo={Number(titulosReceber.receber_vence_30_dias) > 0}
              onClick={
                Number(titulosReceber.receber_vence_30_dias) > 0
                  ? () =>
                    handlerClickModalFinanceiro(
                      "titulos_receber",
                      "vence_trinta_dias",
                    )
                  : () => { }
              }
            >
              <Card
                titulo="Vence em Trinta Dias"
                valor={formataValorMoeda(
                  Number(titulosReceber.receber_vence_30_dias),
                )}
                cor="verde"
              />
            </ButtonStyled>
          </Col>
        </Row>

        <Row gutter={[12, 16]}>
          <Col xs={24} sm={12} xl={6}>
            <ButtonStyled
              temSaldo={Number(titulosReceber.receber_ch_vencidos) > 0}
              onClick={
                Number(titulosReceber.receber_ch_vencidos) > 0
                  ? () =>
                    handlerClickModalFinanceiro(
                      "titulos_receber_cheque",
                      "vencidos",
                    )
                  : () => { }
              }
            >
              <Card
                titulo="Cheques Vencidos"
                valor={formataValorMoeda(
                  Number(titulosReceber.receber_ch_vencidos),
                )}
                cor="vermelho"
              />
            </ButtonStyled>
          </Col>

          <Col xs={24} sm={12} xl={6}>
            <ButtonStyled
              temSaldo={Number(titulosReceber.receber_ch_vence_hoje) > 0}
              onClick={
                Number(titulosReceber.receber_ch_vence_hoje) > 0
                  ? () =>
                    handlerClickModalFinanceiro(
                      "titulos_receber_cheque",
                      "hoje",
                    )
                  : () => { }
              }
            >
              <Card
                titulo="Cheque Vence Hoje"
                valor={formataValorMoeda(
                  Number(titulosReceber.receber_ch_vence_hoje),
                )}
                cor="azul"
              />
            </ButtonStyled>
          </Col>

          <Col xs={24} sm={12} xl={6}>
            <ButtonStyled
              temSaldo={Number(titulosReceber.receber_ch_vence_sete_dias) > 0}
              onClick={
                Number(titulosReceber.receber_ch_vence_sete_dias) > 0
                  ? () =>
                    handlerClickModalFinanceiro(
                      "titulos_receber_cheque",
                      "vence_sete_dias",
                    )
                  : () => { }
              }
            >
              <Card
                titulo="Cheque Vence em Sete Dias"
                valor={formataValorMoeda(
                  Number(titulosReceber.receber_ch_vence_sete_dias),
                )}
                cor="verde"
              />
            </ButtonStyled>
          </Col>

          <Col xs={24} sm={12} xl={6}>
            <ButtonStyled
              temSaldo={Number(titulosReceber.receber_ch_vence_30_dias) > 0}
              onClick={
                Number(titulosReceber.receber_ch_vence_sete_dias) > 0
                  ? () =>
                    handlerClickModalFinanceiro(
                      "titulos_receber_cheque",
                      "vence_trinta_dias",
                    )
                  : () => { }
              }
            >
              <Card
                titulo="Cheque Vence em Trinta Dias"
                valor={formataValorMoeda(
                  Number(titulosReceber.receber_ch_vence_30_dias),
                )}
                cor="verde"
              />
            </ButtonStyled>
          </Col>
        </Row>
      </GrupoCards>

      <GrupoCards titulo="Titulos a Pagar">
        <Row gutter={[12, 16]}>
          <Col xs={24} sm={12} xl={6}>
            <ButtonStyled
              temSaldo={Number(titulosPagar?.pagar_vencidos) > 0}
              onClick={
                Number(titulosPagar?.pagar_vencidos) > 0
                  ? () =>
                    handlerClickModalFinanceiro("titulos_pagar", "vencidos")
                  : () => { }
              }
            >
              <Card
                titulo="Vencidos"
                valor={formataValorMoeda(Number(titulosPagar?.pagar_vencidos))}
                cor="vermelho"
              />
            </ButtonStyled>
          </Col>
          <Col xs={24} sm={12} xl={6}>
            <ButtonStyled
              temSaldo={Number(titulosPagar?.pagar_vence_hoje) > 0}
              onClick={
                Number(titulosPagar?.pagar_vence_hoje) > 0
                  ? () => handlerClickModalFinanceiro("titulos_pagar", "hoje")
                  : () => { }
              }
            >
              <Card
                titulo="Vence Hoje"
                valor={formataValorMoeda(
                  Number(titulosPagar?.pagar_vence_hoje),
                )}
                cor="azul"
              />
            </ButtonStyled>
          </Col>
          <Col xs={24} sm={12} xl={6}>
            <ButtonStyled
              temSaldo={Number(titulosPagar?.pagar_vence_sete_dias) > 0}
              onClick={
                Number(titulosPagar?.pagar_vence_sete_dias) > 0
                  ? () =>
                    handlerClickModalFinanceiro(
                      "titulos_pagar",
                      "vence_sete_dias",
                    )
                  : () => { }
              }
            >
              <Card
                titulo="Vence em Sete Dias"
                valor={formataValorMoeda(
                  Number(titulosPagar?.pagar_vence_sete_dias),
                )}
                cor="verde"
              />
            </ButtonStyled>
          </Col>
          <Col xs={24} sm={12} xl={6}>
            <ButtonStyled
              temSaldo={Number(titulosPagar?.pagar_vence_30_dias) > 0}
              onClick={
                Number(titulosPagar?.pagar_vence_30_dias) > 0
                  ? () =>
                    handlerClickModalFinanceiro(
                      "titulos_pagar",
                      "vence_trinta_dias",
                    )
                  : () => { }
              }
            >
              <Card
                titulo="Vence em Trinta Dias"
                valor={formataValorMoeda(
                  Number(titulosPagar?.pagar_vence_30_dias),
                )}
                cor="verde"
              />
            </ButtonStyled>
          </Col>
        </Row>
      </GrupoCards>

      <Row gutter={[12, 16]}>
        <Col md={24} lg={24} xs={24} sm={24} xl={12}>
          <GrupoCards titulo="Saldos Disponibilidades">
            <TabelaResponsiva
              style={{ width: "100%" }}
              size="small"
              columns={colunasTabelaContaDisponibilidade}
              dataSource={contasDisponibilidade}
              summary={() => (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2}>
                    <Text strong>Total</Text>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Text
                        style={{
                          color:
                            Number.parseFloat(saldoTotalContas) >= 0
                              ? "#1cc88a"
                              : "#e74a3b",
                        }}
                        strong
                      >
                        {formataValorMoeda(saldoTotalContas)}
                      </Text>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            />
          </GrupoCards>
        </Col>
      </Row>

      <Row gutter={[12, 16]}>
        <Col md={24} lg={24} xs={24} sm={24} xl={24}>
          <GrupoCards titulo="Fluxo de Caixa">
            <Col flex="0 1 130px">
              <Radio.Group
                size="midlle"
                style={{ width: "61%" }}
                onChange={handlerClickSearch}
              >
                <Radio.Button value="10">10</Radio.Button>
                <Radio.Button value="20">20</Radio.Button>
                <Radio.Button value="30">30</Radio.Button>
                <Radio.Button value="60">60</Radio.Button>
                <Radio.Button value="90">90</Radio.Button>
                <Radio.Button value="120">120</Radio.Button>
              </Radio.Group>
              <InputSearch
                style={{ padding: "4px", width: "30%", marginLeft: "5%" }}
                placeholder="Pesquiser por quantidade de dias"
                value={searchDiasInputFluxoCaixa}
                onChange={changeInputFluxoCaixa}
              />
              <Button
                style={{
                  marginLeft: "1%",
                  padding: "3px",
                  alignItems: "center",
                  textAlign: "center",
                  background: "#1e88e5",
                  cursor: "pointer",
                }}
              >
                <SearchOutlined
                  style={{
                    padding: "5px",
                    color: "white",
                  }}
                  onClick={() => handleClickDiasFluxoCaixa()}
                />
              </Button>
            </Col>
            <TabelaResponsiva
              style={{ width: "100%" }}
              scroll={{ x: 550, y: "100%" }}
              size="small"
              columns={colunasFluxoCaixa}
              dataSource={fluxoCaixa}
              rowKey={(record) => record.data_vencimento}
            />
          </GrupoCards>
        </Col>
      </Row>
      <Row gutter={[12, 16]}>
        <Col md={24} lg={24} xs={24} sm={24} xl={24}>
          <GrupoCards titulo="Grafico do Fluxo de Caixa">
            <LineChart {...config} />
          </GrupoCards>
        </Col>
      </Row>
    </>
  );
};

export default DashboardFinanceiro;
