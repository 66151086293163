import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;

  flex-direction: column;
  gap: 12px;
`;

export const WrapperTable = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  background: #fff;
  overflow: hidden;
`;


export const ContainerTable = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;

  /* max-height: 600px;
  min-height: 200px; */
`;

export const Table = styled.table`
  width: 100%;
  height: 100%;
`;

export const TableHeader = styled.thead`
  background-color: rgba(249, 250, 251, 1);
  /* position: sticky;
  top: 0;
  left: 0; */

  ${({ isBlockStructureTable }) => isBlockStructureTable &&
    css`
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    `
  }
`;

export const TableBody = styled.tbody`
  width: 100%;
  max-height: 358px;
  background-color: #fff;
`;

export const TableFooter = styled.tfoot``;

export const TableBodyRow = styled.tr`
  cursor: ${({ cursorPointer }) => cursorPointer ? 'pointer' : "default"};

  border-bottom: 1px solid rgba(229, 231, 235, 1);
  transition: background 0.3s ease-in;

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background: #F9FAFB;
  }

  ${({ isBlockStructureTable }) => isBlockStructureTable &&
    css`
      border: 1px solid rgba(229, 231, 235, 1);
      padding: 12px;
      display: block;
      margin-bottom: .625em;
      border-radius: 8px;
    `
  }
`;
export const TableHeaderRow = styled.tr``;

export const TableHead = styled.th`
  text-transform: uppercase;
  padding: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  color: #6B7280;
  letter-spacing: 0.05rem;
  white-space: nowrap;

  @media screen and (max-width: 425px) {
    padding: 0.6rem;
  }

  text-align: ${({ align }) => align ? align : "left"};

  ${({ pinning, isBlockStructureTable }) => (pinning && !isBlockStructureTable) && css`
     position: sticky !important;
     left: 0;
     top: 0;
     z-index: 1;
     background-color: rgba(249, 250, 251, 1);
     box-shadow: inset -4px 0 4px -2px rgb(0 0 0 / 0.1);

     @media screen and (max-width: 425px) {
       & {
         min-width: initial;
       }
     }
  `}
`;

export const TableCell = styled.td`
  min-width: ${({ width }) => width ? `${width}px` : "auto"};
  text-align: ${({ align }) => align ? align : "left"};
  padding: 0.5rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 100.25rem;

  ${({ pinning, isBlockStructureTable }) => (pinning && !isBlockStructureTable) && css`
    position: sticky !important;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: #fff;
    box-shadow: inset -4px 0 4px -2px rgb(0 0 0 / 0.1);
  `}

  @media screen and (max-width: 425px) {
    padding: 0.6rem;
  }

  ${({ isBlockStructureTable }) => isBlockStructureTable &&
    css`
      display: block;
      text-align: right;
      font-size: 14px;
      width: 100%;
      padding: 4px 0;

      &::before {
        content: attr(data-label);
       float: left;
        font-size: 14px;
        color: #6B7280;
        font-weight: bold;
        text-transform: uppercase;
      }
    `
  }
`;

export const DetailButtonContainer = styled.button`
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: transparent;
  border: none;

  &:hover {
    color: #1890ff;
    cursor: pointer;
  }

  &:disabled {
    font-weight: lighter;
    cursor: not-allowed;
    color: #9CA3AF;
  }
`
