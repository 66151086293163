import styled  from "styled-components";
 
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: end;
    justify-content: end;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const GroupActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
