import styled from "styled-components";

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #fff;
  padding: 1rem
`;

export const ContainerMapa = styled.div`
  margin-bottom: 16px;
  border-radius: 3px;
`;

export const SectionTitle = styled.h2`
  padding: 0;
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.75rem;
`;

export const ContainerFiltro = styled.div`
  margin-bottom: 16px;
  border-radius: 3px;
  background: #fff;

  span {
    align-items: center;
    padding: 0 0 0 4px;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .boxFilter {
    display: flex;
    flex-direction: row;

    @media (min-width: 320px) and (max-width: 767px){
      flex-direction: column;
    }
  }
`;

export const ContainerLabels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .subtitles {
    display: flex;
    flex-direction: row;
    gap: 24px;
    color: black;
    font-size: 16px;

    @media (min-width: 320px) and (max-width: 760px){
      display: flex;
      flex-direction: column;
    }

    div {
      display: flex;
      gap: 4px
    }

    p {
      margin: 0;
      padding: 0;
    }

  }
`;

export const MarkerVisibilityButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 8px;
  cursor: pointer;
  border-radius: 3px;
  background-color: ${(props) => props.$bgColor};
  /* color: #020617; */
  color: #f5f5f5;
  font-weight: bold;

  &[data-active=false] {
    color: #334155;
  }
`;

export const ButtonStyled = styled.button`
  width: 100%;
  height: auto;
  padding: 2px;
  margin: 1px solid transparent;
  text-align: left;
  background-color: #1e88e5;
  color: #FFFFFF;
  outline: none;
`;
