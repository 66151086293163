import React, { useEffect, useState } from 'react';
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
  MarkerClusterer
} from "@react-google-maps/api";



import yellowMarker from "../../assets/map/yellow-marker.png"
import greenMarker from "../../assets/map/green-marker.png"
import redMarker from "../../assets/map/red-marker.png"
import orangeMarker from "../../assets/map/orange-marker.png"
import blueMarker from "../../assets/map/blue-marker.png"
import { LoadingContainer } from './styles';

const Maps = ({ locations, clickedElementOnTable }) => {
  const [mapRef, setMapRef] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [center, setCenter] = useState({ lat: -27.241389, lng: -48.633611 });
  const [zoom, setZoom] = useState(10);
  const [infoOpen, setInfoOpen] = useState(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA9QueaGagaJ7DQmOkMGDwOjMhgSQtb5vU",
  });

  const mapContainerStyle = {
    height: "60vh",
    width: "100%"
  };

  const markerLoadHandler = (marker, place) => {
    return setMarkerMap(prevState => {
      return { ...prevState, [place.uid]: marker };
    });
  };

  const onCenterChanged = mapRef => {
    if (mapRef?.getCenter()) {
      const ccenter = mapRef.getCenter().toJSON();
      if (ccenter.lat !== center.lat && ccenter.lng !== center.lng) {
        setCenter(mapRef.getCenter().toJSON());
      }
    }
  };

  const markerClickHandler = (event, place) => {
    // Remember which place was clicked
    setSelectedPlace(place);

    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    // If you want to zoom in a little on marker click
    if (zoom < 13) {
      setZoom(10);
    }

    // if you want to center the selected Marker
    setCenter(place.pos);
  };

  const icons = {
    info: {
      0: {
        url: redMarker,
        scaledSize: { width: 40, height: 40 }
      },
      1: {
        url: greenMarker,
        scaledSize: { width: 40, height: 40 }
      },
      2: {
        url: yellowMarker,
        scaledSize: { width: 40, height: 40 }
      },
    },
    parking: {
      0: {
        url: orangeMarker,
        scaledSize: { width: 40, height: 40 }
      },
      1: {
        url: blueMarker,
        scaledSize: { width: 40, height: 40 }
      }
    }
  };

  useEffect(() => {
    setCenter(clickedElementOnTable)
  }, [clickedElementOnTable])

  const renderMap = () => {
    return (
      <GoogleMap
        id="maps"
        onCenterChanged={() => onCenterChanged(mapRef)}
        center={center}
        zoom={zoom}
        mapContainerStyle={mapContainerStyle}
        options={{
          streetViewControl: false
        }}
      >
        <MarkerClusterer>
          {clusterer =>
            locations.map(location => (
              <Marker
                key={location.uid}
                position={{
                  lng: Number.parseFloat(location.lng),
                  lat: Number.parseFloat(location.lat)
                }}
                icon={icons[location.type][location.status]}
                clusterer={clusterer}
                onLoad={marker => markerLoadHandler(marker, location)}
                onClick={event => markerClickHandler(event, location)}
              />
            ))
          }
        </MarkerClusterer>
        {infoOpen && selectedPlace && (
          <InfoWindow
            anchor={markerMap[selectedPlace.uid]}
            onCloseClick={() => setInfoOpen(false)}
          >
            <div>
              <h3>{selectedPlace.uid}</h3>
              <div>Placa: {selectedPlace.placa}</div>
              <div> {selectedPlace.user_name} </div>
              <div> {selectedPlace.veiculos} </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  };

  return isLoaded
    ? renderMap()
    : <LoadingContainer style={mapContainerStyle}>Carregando mapa... </LoadingContainer>

}

export default Maps;
