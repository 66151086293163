import React, { useState, useEffect, useContext, useCallback } from "react";
import { Table, Spin, Row } from "antd";
import { Context } from "../Context/AuthContext";
import api from "../service/api";
import { WrapperTables } from "../components/TabelaPersonalizada/styles";

import moment from "moment";
import Text from "antd/lib/typography/Text";
import { round } from "../utils/round";
import TabelaPersonalizada from "./TabelaPersonalizada";

const filtroTituloReceber = {
  vencidos: {
    data_inicio: moment().subtract("20", "years").format("YYYY/MM/DD"),
    data_final: moment().subtract("1", "day").format("YYYY/MM/DD"),
    codigo_cliente: "0",
  },
  hoje: {
    data_inicio: moment().format("YYYY/MM/DD"),
    data_final: moment().format("YYYY/MM/DD"),
    codigo_cliente: "0",
  },
  vence_sete_dias: {
    data_inicio: moment().add("1", "day").format("YYYY/MM/DD"),
    data_final: moment().add("7", "day").format("YYYY/MM/DD"),
    codigo_cliente: "0",
  },
  vence_trinta_dias: {
    data_inicio: moment().add("1", "day").format("YYYY/MM/DD"),
    data_final: moment().add("30", "day").format("YYYY/MM/DD"),
    codigo_cliente: "0",
  },
};

const colunasTabela = [
  {
    title: "Data Emissão",
    dataIndex: "dataEmissao",
    key: "dataEmissao",
    width: 130,
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Data Vencimento",
    dataIndex: "dataVencimento",
    key: "dataVencimento",
    width: 130,
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Dias Atraso",
    dataIndex: "diasAtraso",
    key: "diasAtraso",
    width: 110,
    align: "right",
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Nro. Título",
    dataIndex: "nroTitulo",
    key: "nroTitulo",
    width: 110,
    align: "right",
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Parcela",
    dataIndex: "nroParcela",
    key: "nroParcela",
    width: 70,
    align: "center",
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Série",
    dataIndex: "serie",
    key: "serie",
    width: 70,
    align: "center",
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Valor",
    dataIndex: "valor",
    key: "valor",
    width: 110,
    align: "right",
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Saldo",
    dataIndex: "saldo",
    key: "saldo",
    width: 110,
    align: "right",
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Descontado",
    dataIndex: "vlr_descontado",
    key: "vlr_descontado",
    width: 110,
    align: "right",
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Líquido",
    dataIndex: "vlr_liquido",
    key: "vlr_liquido",
    width: 110,
    align: "right",
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Nro. Doc. Origem",
    dataIndex: "nroDocOrigem",
    key: "nroDocOrigem",
    width: 140,
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Tipo Doc. Origem",
    dataIndex: "tipoDocOrigem",
    key: "tipoDocOrigem",
    width: 140,
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Nro. DF-e",
    dataIndex: "nroDFe",
    key: "nroDFe",
    width: 110,
    align: "right",
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Tipo DF-e",
    dataIndex: "tipoDFe",
    key: "tipoDFe",
    width: 110,
    align: "right",
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Cód. Cliente",
    dataIndex: "idCliente",
    key: "idCliente",
    width: 110,
    align: "right",
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Nome Cliente",
    dataIndex: "nomeCliente",
    key: "nomeCliente",
    width: 500,
    isVisible: true,
    isSearch: true,
  },
  {
    title: "Observação",
    dataIndex: "observacao",
    key: "observacao",
    width: 800,
    isVisible: true,
    isSearch: true,
  },
];

function formataValorMoeda(value) {
  let valorFormatado = "--";

  if (value || value === 0) {
    valorFormatado = Number.parseFloat(value).toLocaleString("pt-br", {
      minimumFractionDigits: 2,
    });
  }
  return valorFormatado;
}

const TitulosReceber = (props) => {
  const [titulosReceber, setTitulosReceber] = useState([]);
  const [valores, setValores] = useState({
    saldo: 0.00,
    valor: 0.00,
    descontado: 0.00,
    liquido: 0.00
  });
  const [carregando, setCarregando] = useState([false]);
  const { empresa } = useContext(Context);
  const tipoRelatorio = props.prazo;
  const parametrosPesquisa = filtroTituloReceber[tipoRelatorio];

  const consultaTitulosReceber = useCallback(async (dadosConsulta) => {
    try {
      setCarregando(true);
      let dadosValidosConsulta = dadosConsulta

      if (!dadosConsulta) {

        const dataFormatAmerican = props.dataPeriodo.split('/');
        const dataFormatAmericanFluxoCaixa = `${dataFormatAmerican[2]}/${dataFormatAmerican[1]}/${dataFormatAmerican[0]}`;

        dadosValidosConsulta = {
          codigo_cliente: 0,
          data_inicio: dataFormatAmericanFluxoCaixa,
          data_final: dataFormatAmericanFluxoCaixa
        }
      }

      const {
        data: { response },
      } = await api.get(`titulos-receber/${empresa.id}`, {
        params: {
          codigo_cliente: dadosValidosConsulta.codigo_cliente,
          data_inicio: dadosValidosConsulta.data_inicio,
          data_final: dadosValidosConsulta.data_final,
        },
      });

      const dadosTabela = response.map((titulo, key) => {
        return {
          key: `titulo${key}`,
          ...titulo,
          dataEmissao: moment(titulo.dataEmissao).format("DD/MM/YYYY"),
          dataVencimento: moment(titulo.dataVencimento).format("DD/MM/YYYY"),
          valor: formataValorMoeda(titulo.valor),
          saldo: formataValorMoeda(titulo.saldo),
          vlr_descontado: formataValorMoeda(titulo.vlr_descontado ?? 0),
          vlr_liquido: formataValorMoeda(titulo.vlr_liquido ?? 0),
        };
      })

      const totalSaldoTitulo = response.reduce(
        (acc, titulo) => acc + Number(titulo.saldo),
        0,
      );

      const totalValorTitulo = response.reduce(
        (acc, titulo) => acc + Number(titulo.saldo),
        0,
      );

      const totalValorDescontos = response.reduce(
        (acc, titulo) => acc + Number(titulo.vlr_descontado),
        0
      )

      const totalValorLiquido = response.reduce(
        (acc, titulo) => acc + Number(titulo.vlr_liquido),
        0
      )


      setValores({
        valor: totalValorTitulo,
        saldo: totalSaldoTitulo,
        descontado: totalValorDescontos,
        liquido: totalValorLiquido
      });
      setTitulosReceber(dadosTabela);
      setCarregando(false);
    } catch (error) { console.log({ error }); }
  }, [empresa, props.dataPeriodo])

  useEffect(() => {
    consultaTitulosReceber(parametrosPesquisa)
  }, [consultaTitulosReceber, parametrosPesquisa])

  return (
    <>
      {carregando ? (
        <Row
          justify="center"
          align="middle"
          style={{ height: "calc(100vh / 2)" }}
        >
          <Spin size="large" />
        </Row>
      ) : (
        <WrapperTables>
          <TabelaPersonalizada
              titulo="Títulos a Receber"
              columns={colunasTabela}
              dataSource={titulosReceber}
              configTabela={{
                size: "small",
                scroll: { x: "100%", y: "550px" },
                pagination: {
                  defaultPageSize: 20,
                },
                rowkey: ({ record }) => record.key,
                summary: () => (
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={6}>
                      <Text strong>Total</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Text strong>{formataValorMoeda(valores.valor)}</Text>
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Text strong>{formataValorMoeda(valores.saldo)}</Text>
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Text strong>{formataValorMoeda(valores.descontado)}</Text>
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Text strong>{formataValorMoeda(valores.liquido)}</Text>
                      </div>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                ),
              }}
            />
          </WrapperTables>
      )}
    </>
  );
};

export default TitulosReceber;
